import axios from "axios";

export default axios.create({
    baseURL: (process.env.VUE_APP_NODE_ENV == 'prod')? process.env.VUE_APP_URL_PROD:process.env.VUE_APP_URL_DEV,
    headers: {
        "Content-Type": "application/json",
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    withCredentials:true
  
});