import { createStore } from 'vuex'

import nativeModule from "./modules/native"


const store = createStore({
  modules:{
    native: nativeModule
  }
})


export default store;