<template>
  <router-view/>
</template>

<style>
  @import url("https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap");
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
  @import url('https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha3/dist/css/bootstrap.min.css');
  
  body {
    width: 100%;
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction:  column;
    gap: 20px;
  }
  .nav-menu {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 50px;
    padding: 10px 0;
    background-color: #ddf3ff;
  }
  .logo-holder {
    width: 250px;
    position: relative;
    margin-left: 15px;
  }
  .logo-holder img {
    width: 100%;
    height: auto;
    vertical-align: baseline;
  }
  #preview {
    font-family: Dancing Script, cursive;
    font-size: 25px;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .menu {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-evenly;
    align-items: center;
    font-family: 'Open Sans', sans-serif;
  }
  ul {
    list-style: none;

  }
  ul li {
    font-size: 20px;
    padding: 0 15px;
  }
  .upper {
    color: #1b2747;
    font-family: Dancing Script, cursive;
  }
  a {
    text-decoration: none;
    color: #1b2747;
  }
  .footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .footer-text {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
  }
  .reco-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  button {
    border: none;
    border-radius: 15px;
    background-color: #1b2747;
    font-size: 16px;
    color: white;
    padding: 5px 20px;
  }
  #desktop {
    display: none;
  }
</style>


