import http from "../../../axios";

const urls = {
    native: '/native/'
};

const state = () => ({
  creatives: undefined,
  chosenCreative:undefined
});

const getters = {
  getCreatives(state){
    return state.creatives
  },
  getChosenCreative(state){
    return state.chosenCreative
  }
};
const actions = {
    async getApiCreatives({commit}, path){
        try {
            const response = await http.get(urls.native + path)
            if(response && response.data){
                commit("setCreatives", response.data)
            }
        } catch (error) {
            console.log(error)
        }
    },
    setOneCreative({commit}, payload){
        commit("setChosenCreative", payload)
    }
};
const mutations = {
  setCreatives(state,data){
    state.creatives = data
  },
  setChosenCreative(state,data){
    state.chosenCreative = data
  }
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
